import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Center,
  Image,
  Flex,
  useToast,
  Grid, 
  GridItem,
  Stack,
  Container,
  Badge,
  Alert,
  AlertIcon,
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
  Button,
  Progress,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { TriangleUpIcon, TriangleDownIcon, WarningIcon, TimeIcon, SearchIcon } from '@chakra-ui/icons';
import React, { useState, useEffect, useCallback } from "react";

function App() {
  localStorage.setItem("chakra-ui-color-mode", "light");
  const toast = useToast();
  const [symbolsData, setSymbolsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure(); // for Modal


  const fetchSymbolsData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.bitkub.com/api/market/ticker`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (!result.error) {
        setSymbolsData(result);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
      } else {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
        toast({
          title: 'เกิดข้อผิดพลาด',
          description: result.error_message || "ไม่สามารถดึงข้อมูลได้",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'เกิดข้อผิดพลาด',
        description: error.message || "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.error("Error fetching data:", error);
    }
  }, [toast]);

  useEffect(() => {
    fetchSymbolsData();
    const intervalId = setInterval(fetchSymbolsData, 2500);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchSymbolsData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const priceRanges = [
    { label: "ไม่ระบุ", value: "" },
    { label: "ต่ำกว่า 1,000", value: "1000" },
    { label: "ต่ำกว่า 10,000", value: "10000" },
    { label: "ต่ำกว่า 50,000", value: "50000" },
    { label: "ต่ำกว่า 100,000", value: "100000" },
    { label: "ต่ำกว่า 500,000", value: "500000" },
    { label: "ต่ำกว่า 1,000,000", value: "1000000" },
    { label: "สูงกว่า 1,000", value: "-1000" },
    { label: "สูงกว่า 10,000", value: "-10000" },
    { label: "สูงกว่า 50,000", value: "-50000" },
    { label: "สูงกว่า 100,000", value: "-100000" },
    { label: "สูงกว่า 500,000", value: "-500000" },
    { label: "สูงกว่า 1,000,000", value: "-1000000" },
  ];

  // Modified filtering logic to handle both minPrice and maxPrice correctly
  const filteredSymbols = Object.keys(symbolsData).filter((symbol) => {
    const price = symbolsData[symbol].last;
    const matchesSearch = symbol.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesMinPrice = minPrice === "" || price >= parseFloat(minPrice);
    const matchesMaxPrice = maxPrice === "" || price <= parseFloat(maxPrice);
    return matchesSearch && matchesMinPrice && matchesMaxPrice;
  });

  return (
    <Box
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      minH="100vh"
    >
      <Box bg={'rgba(255, 255, 255, 0.8)'} px={4} position="fixed" top={0} left={0} right={0} zIndex={999}>
      {loading &&  <Progress size='xs' isIndeterminate/> }
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <Text as="b">ระบบแสดงราคาคริปโทเคอร์เรนซีเรียวไทม์</Text>
          </Box>
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
            <Button colorScheme='teal' variant='ghost' onClick={onOpen}>
              ผู้จัดทำ
            </Button>
            </Stack>
          </Flex>
        </Flex>
      </Box>

      <Center mt={24}>
        <Flex align="center">
          <Text>ข้อมูลตรงจาก</Text>
          <Image w={48} src='/logo.png' alt='BitKub Logo' />
        </Flex>
      </Center>

      <Center mt={4}>
        <InputGroup width={isLargerThan768 ? "50%" : "90%"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input type="text" placeholder="ค้นหา Symbol" value={searchQuery} onChange={handleSearchChange} />
        </InputGroup>
      </Center>

      <Center mt={4}>
        <Flex width={isLargerThan768 ? "50%" : "90%"} justify="space-between">
          <Select
            placeholder="ราคาต่ำสุด"
            value={minPrice}
            onChange={handleMinPriceChange}
          >
            {priceRanges
              .filter((range) => range.value >= 0)
              .map((range) => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
          </Select>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            placeholder="ราคาสูงสุด"
            value={maxPrice}
            onChange={handleMaxPriceChange}
          >
            {priceRanges
              .filter((range) => range.value < 0)
              .map((range) => (
                <option key={range.value} value={Math.abs(range.value)}>
                  {range.label}
                </option>
              ))}
          </Select>
        </Flex>
      </Center>

      <Center>
        <Accordion mt={3} allowToggle w={isLargerThan768 ? "50%" : "90%"}>
          {filteredSymbols.map((symbol) => (
            <AccordionItem key={symbol}>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Badge fontSize='0.8em'  colorScheme='purple'><WarningIcon /> {symbol.replace("THB_", "")}</Badge> &nbsp;
                    <Badge fontSize='0.8em'  colorScheme='green'>ราคาสูงสุด {symbolsData[symbol].highestBid.toLocaleString(undefined, {maximumFractionDigits: 2})} THB  <TriangleUpIcon /></Badge> &nbsp;
                    <Badge fontSize='0.8em'  colorScheme='red'>ราคาต่ำสุด {symbolsData[symbol].lowestAsk.toLocaleString(undefined, {maximumFractionDigits: 2})} THB  <TriangleDownIcon /></Badge>&nbsp;
                    <Badge fontSize='0.8em'  colorScheme='blue'>ราคาล่าสุด {symbolsData[symbol].last.toLocaleString(undefined, {maximumFractionDigits: 2})} THB  <WarningIcon /></Badge>

                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box maxW='full' p={3} borderWidth='1px' borderRadius='lg' overflow='hidden' >
                  <Grid templateColumns={isLargerThan768 ? "repeat(2, 1fr)" : "repeat(1, 1fr)"} gap={1}>
                    <GridItem>
                      <Badge fontSize='0.8em'  variant='subtle' colorScheme='blue'>
                        ราคาล่าสุด <WarningIcon />
                      </Badge>
                      <Text fontWeight="bold">{symbolsData[symbol].last.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท</Text>
                      <Badge fontSize='0.8em'  variant='subtle' colorScheme='red'>
                        ราคาต่ำสุด <TriangleDownIcon />
                      </Badge>
                      <Text fontWeight="bold">{symbolsData[symbol].lowestAsk.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท</Text>
                      <Badge fontSize='0.8em'  variant='subtle' colorScheme='green'>
                        ราคาสูงสุด <TriangleUpIcon />
                      </Badge>
                      <Text fontWeight="bold">{symbolsData[symbol].highestBid.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท </Text>
                      <Badge fontSize='0.8em'  colorScheme='red'>เปลี่ยนแปลงเปอร์เซ็นต์ <TimeIcon /></Badge>
                      <Text fontWeight="bold">{symbolsData[symbol].percentChange}%</Text>
                    </GridItem>
                    <GridItem>
                      <Alert status='info' variant='top-accent'>
                        <AlertIcon />
                        ปริมาณฐาน: {symbolsData[symbol].baseVolume.toLocaleString(undefined, {maximumFractionDigits: 2})} {symbol.replace("THB_", "")}
                      </Alert>
                      <Alert status='warning' variant='top-accent'>
                        <AlertIcon />
                        ปริมาณอ้างอิง: {symbolsData[symbol].quoteVolume.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท
                      </Alert>
                      <Alert status='success' variant='top-accent'>
                        <AlertIcon />
                        สูง 24 ชั่วโมง: {symbolsData[symbol].high24hr.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท
                      </Alert>
                      <Alert status='error' variant='top-accent'>
                        <AlertIcon />
                        ต่ำ 24 ชั่วโมง: {symbolsData[symbol].low24hr.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท
                      </Alert>
                    </GridItem>
                    <GridItem>
                      <Badge fontSize='0.8em'  colorScheme='purple'>การเปลี่ยนแปลง: {symbolsData[symbol].change.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท</Badge> &nbsp;
                      <Badge fontSize='0.8em'  colorScheme='green'>ราคาปิดก่อนหน้า: {symbolsData[symbol].prevClose.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท</Badge> &nbsp;
                      <Badge fontSize='0.8em'  colorScheme='red'>ราคาเปิดก่อนหน้า: {symbolsData[symbol].prevOpen.toLocaleString(undefined, {maximumFractionDigits: 2})} บาท</Badge>
                    </GridItem>
                  </Grid>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ผู้จัดทำ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>นายธีรภัทร ถาวัง 67319010012</Text>
            <Text>นายสุบิน วังมา 67319010066</Text>
            <Text>นางสาวนภาพร อุทร 67319010085</Text>
            <Text>นางสาวชญาณิศา ทองออน 67319010099</Text>
            <Text>นางสาวกานต์ธิดา จ่าดวงใจ 67319010100</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              ปิด
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        bg={'rgba(255, 255, 255, 0.8)'}
        color={'gray.700'}
        // position="fixed"
        bottom={0}
        left={0}
        right={0}
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>© 2024 เสนอ อ.เมธาวิณทร์ เมษาเทียนจันทร์</Text>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
